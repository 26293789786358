// Ensure the authenticity token in the <meta name="csrf-token"> tag is updated when Unpoly replaces page fragments.
// This is necessary because activity_form.js triggers requests with paths that do not match the form's authenticity token,
// and without refreshing the meta tag, stale tokens can lead to CSRF validation errors.
up.radio.config.hungrySelectors.push('[name="csrf-token"]')

up.fragment.config.mainTargets.push('body')

up.link.config.followSelectors.push('a[href]')
up.link.config.noFollowSelectors.push('[download]')

up.link.config.preloadSelectors.push('a[href]')
up.link.config.noPreloadSelectors.push('.btn:not(.btn-link)')

up.form.config.submitSelectors.push('form')
