const QuickSearch = {

  connect(containerElement) {
    // Participating elements
    this.containerElement = containerElement
    this.magnifierElement = this.containerElement.querySelector('#quick_search_magnifier')
    this.queryElement = this.containerElement.querySelector('#quick_search_query')
    this.resultsElement = this.containerElement.querySelector('#quick_search_result_target')

    // State
    this.opened = false
    this.fetching = false
    this.resultsForQuery = null
    this.searchPath = this.containerElement.dataset.path

    this.bindEvents()
  },

  bindEvents() {
    // When user clicks on modifier, open the search
    this.magnifierElement.addEventListener('click', (_event) => {
      this.setOpened()
    })

    // When user clicks on the background, close the search
    document.addEventListener('click', (event) => {
      if (!this.isEventWithinContainer(event)) {
        this.setOpened(false)
      }
    })

    document.addEventListener('keydown', (event) => {
      // When the user presses escape anywhere, close the search
      if (event.key === 'Escape') {
        this.setOpened(false)
      }

      // If the user presses "F" outside an input field, open the search
      if (event.key?.toUpperCase() === 'F' && this.isUnmodifiedEvent(event) && !this.isInputFocused()) {
        this.setOpened(true)
        event.preventDefault()
      }
    })

    const debouncedFetchResults = _.debounce(() => this.fetchResults(), 300)
    this.queryElement.addEventListener('input', debouncedFetchResults)

    this.queryElement.addEventListener('keydown', (event) => {
      if (event.key === 'Enter') {
        this.fetchResults()
      }
    })
  },

  isEventWithinContainer: function(event) {
    return this.containerElement.contains(event.target)
  },

  isInputFocused() {
    const focusedElement = document.activeElement
    return focusedElement && focusedElement.closest('input, textarea, select')
  },

  isUnmodifiedEvent(event) {
    const leftButtonClicked = _.isUndefined(event.button) || event.button === 0
    return leftButtonClicked && !event.metaKey && !event.shiftKey && !event.ctrlKey && !event.altKey
  },

  setOpened: function(newOpened) {
    if (_.isUndefined(newOpened)) {
      newOpened = !this.opened
    }

    this.containerElement.classList.toggle('-opened', newOpened)
    this.magnifierElement.classList.toggle('active', newOpened)

    if (!this.opened && newOpened) {
      this.queryElement.focus()
    }

    this.opened = newOpened
  },

  fetchResults: async function() {
    const query = this.queryElement.value

    // If we're already waiting for a server response, don't do anything.
    // We will re-check the current query when we're done.
    if (this.fetching || !query || query === this.resultsForQuery) {
      return
    }

    this.setFetching(true)
    const response = await fetch(this.searchPath + '?query=' + encodeURIComponent(query))
    const html = await response.text()
    this.setFetching(false)

    if (query === this.queryElement.value) {
      // Only render if the user did not change the query in the meantime.
      this.resultsElement.innerHTML = html
      this.resultsForQuery = query
    } else {
      // If the user has changed the query in the meantime, fetch again.
      await this.fetchResults()
    }
  },

  setFetching: function(newOpened) {
    this.fetching = newOpened
    this.containerElement.classList.toggle('-fetching', newOpened)
  },

}

up.compiler('#quick_search', QuickSearch.connect.bind(QuickSearch))
